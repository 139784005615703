import type { AuthResponse } from '@services/client/auth/useSignUp'
import { safeStorage } from '@utils/storage'
import { create } from 'zustand'
import { queryClient } from '@services/query'
import { persist, createJSONStorage } from 'zustand/middleware'
import { logLogout, setUserId } from '@utils/analytics'
import { isValidJWT } from '@services/client/patient'
import http from '@services/HTTP'

type AuthCallback = {
  onAuth?: () => void
  onClose?: () => void
}
interface AuthState {
  isLogged: boolean
  token: string
  renew: string
  userHash: string
  isHydrated: boolean
  setHydrated: () => void
  updateToken: (auth: AuthResponse) => void
  logOut: () => void
  showAuth: boolean
  type: 'login' | 'signup'
  showResetPassword: boolean
  authCallback?: AuthCallback
  requestAuth: (params?: AuthCallback & { type?: 'login' | 'signup' }) => void
  onAuthClose: () => void
  onSuccessAuth: () => void
  requestResetPassword: () => void
  closeResetPassword: () => void
}

export const useAuthState = create<AuthState>()(
  persist(
    (set, get) => ({
      isLogged: false,
      token: null,
      renew: null,
      userHash: null,
      isHydrated: false,
      showAuth: typeof window === 'undefined' ? false : new URLSearchParams(window.location.search).get('dialog') === 'auth',
      type: 'login',
      showResetPassword: typeof window === 'undefined' ? false : new URLSearchParams(window.location.search).get('dialog') === 'reset-password',
      updateToken: (auth: AuthResponse) => {
        set({
          isLogged: true,
          token: auth.token,
          renew: auth.renew,
          userHash: auth.user
        })
      },
      logOut: () => {
        set({
          isLogged: false,
          token: null,
          renew: null,
          userHash: null
        })
        safeStorage.removeItem('user')
        queryClient.clear()
        logLogout()
        setUserId(null)
      },
      requestAuth: params => {
        set({
          showAuth: true,
          type: params?.type || 'login',
          authCallback: {
            ...params
          }
        })
      },
      onSuccessAuth: () => {
        get().authCallback?.onAuth?.()
        set({
          showAuth: false,
          authCallback: null
        })
      },
      onAuthClose: () => {
        get().authCallback?.onClose?.()
        set({
          showAuth: false,
          type: 'login',
          authCallback: null
        })
      },
      requestResetPassword: () => {
        set({
          showResetPassword: true
        })
      },
      closeResetPassword: () => {
        set({
          showResetPassword: false
        })
      },
      setHydrated: () => {
        set({ isHydrated: true })
      }
    }),
    {
      name: 'auth_state',
      storage: createJSONStorage(() => safeStorage),
      version: 1,
      skipHydration: true,
      partialize: state => ({
        isLogged: state.isLogged,
        token: state.token,
        renew: state.renew,
        userHash: state.userHash
      })
    }
  )
)

useAuthState.persist.onFinishHydration(() => {
  useAuthState.getState().setHydrated()
})

let promise: Promise<string> = null

export const getToken = async (): Promise<string> => {
  if (promise) return promise
  const { token, renew, userHash } = useAuthState.getState()
  if (!token || !renew) return null

  if (!isValidJWT(token)) {
    promise = http
      .post<{ jwt_renew: string; user_hash: string }, AuthResponse>(
        '/authenticate/token/renew',
        {
          jwt_renew: renew,
          user_hash: userHash
        },
        {
          headers: {
            Authorization: null
          }
        }
      )
      .then(res => {
        useAuthState.getState().updateToken(res)
        promise = null
        return res.token
      })
      .catch(e => {
        console.warn('Error renewing token, logging out', e)
        useAuthState.getState().logOut()
        queryClient.removeQueries({ queryKey: ['user'], type: 'all' })
        promise = null
        return null
      })

    return promise
  }
  return token
}
