import type React from 'react'
import type { ButtonHTMLAttributes, JSXElementConstructor } from 'react'
import cn from 'clsx'
import Link from 'next/link'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  Component?: React.ElementType | JSXElementConstructor<any>
  href?: string
  variant?: 'square' | 'circle'
}
const IconButton: FC<Props> = ({ href, Component = href ? Link : 'button', children, variant = 'circle', className, ...rest }) => {
  const classname = cn(
    'flex h-fit bg-transparent hover:bg-primary-100 transition-colors p-2 aspect-square disabled:opacity-50 disabled:pointer-events-none disabled:cursor-not-allowed',
    {
      'rounded-lg border border-solid border-gray-100': variant === 'square',
      'rounded-full border-none': variant === 'circle'
    },
    className
  )

  return (
    <Component className={classname} {...(href && { href })} {...rest}>
      {children}
    </Component>
  )
}

export default IconButton
