import type { TokenResponse } from '@react-oauth/google'
import useExchange from '@services/client/auth/ useExchange'
import useGetUser from '@services/client/user/useGetUser'
import { useCallback, useEffect, useState } from 'react'
import { useAuthState } from 'store/auth'
import * as Sentry from '@sentry/nextjs'
import { logLogin, logLoginError, setUserId } from '@utils/analytics'

const usePostGoogleLogin = (onAuth?: () => void) => {
  const { refetch, isLoading } = useGetUser()
  const { mutateAsync: exchange } = useExchange()
  const [isGoogleLoading, setIsGoogleLoading] = useState(false)

  const onSuccess = async (data: TokenResponse & { onAuth?: () => void }) => {
    setIsGoogleLoading(true)
    try {
      await login(data)
      data.onAuth?.()
    } catch (error) {
      console.error('Error fetching user info:', error)
    } finally {
      setIsGoogleLoading(false)
      const searchParams = new URLSearchParams(window.location.search)
      if (searchParams.has('google_access_token')) {
        searchParams.delete('google_access_token')
        const newUrl = `${window.location.pathname}${searchParams.toString() ? '?' + searchParams.toString() : ''}`
        window.history.replaceState({}, '', newUrl)
      }
    }
  }

  const login = async (data: TokenResponse) => {
    const expiryDate = new Date(Date.now() + data.expires_in * 1000).toISOString()
    const exchangeBody = {
      provider: 'google',
      accessToken: data.access_token,
      expires: expiryDate
    }

    try {
      const authResponse = await exchange(exchangeBody)
      useAuthState.getState().updateToken(authResponse)
      await refetch()
      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'login'
      })
      logLogin()
      setUserId(authResponse.user)
    } catch (error) {
      console.error('Error exchanging token:', error)
      logLoginError('google', error.message)
    }
  }

  const onError = (err: any) => {
    console.log('err', err)
  }

  return {
    onSuccess,
    onError,
    isLoading: isLoading || isGoogleLoading // Combine both loading states
  }
}

export default usePostGoogleLogin
